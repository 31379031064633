import {GoogleMap, InfoWindow, MarkerF, useLoadScript} from "@react-google-maps/api";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import mapPin from '../../../../assets/img/map-pin.png'

const containerStyle = {
    width: 'auto', // Set your desired width
    height: '700px', // Set your desired height
};


function MapView({id}) {
    const [isLoading, setIsLoading] = useState(true);
    const [activeMarker, setActiveMarker] = useState(null);
    const [data, setData] = useState([]);
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const navigate = useNavigate();

    const center = useMemo(() => {
        if (data.length > 0) {
            return {
                lat: data.reduce((sum, property) => sum + property.lat, 0) / data.length,
                lng: data.reduce((sum, property) => sum + property.lng, 0) / data.length,
            };
        } else {
            return null;
        }
    }, [data]);

    const closeInfoWindow = () => {
        setActiveMarker(null);
    };

    useEffect(() => {
        if (isLoaded) {
            // Load the property listings from the API.
            axios
                .get(`${process.env.REACT_APP_API_URL}/propertymap/${id}`)
                .then((response) => {
                    const propertyListings = response.data.data.map((property) => {
                        const [lat, lng] = property.g_map_location.split(",").map(parseFloat);
                        return {
                            lat,
                            lng,
                            published_title: property.published_title,
                            published_location: property.published_location,
                            price: property.price,
                            image: property.single_image,
                            id: property.id,
                            propertytype: property.propertytype.name
                        };
                    });
                    setData(propertyListings);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [id, isLoaded]);

    const handleMarkerClick = (marker) => {
        setActiveMarker(marker);
    };

    function handleViewProperty(id) {
        navigate(`/project/property/${id}`);
    }

    return (
        <>
            {!isLoading && center ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={13}
                    controlOptions={{
                        zoomControl: true,
                        panControl: true,
                        scaleControl: true,
                    }}
                >
                    {data.map((propertyListing) => (
                        <MarkerF
                            key={propertyListing.lat}
                            position={propertyListing}
                            onClick={() => handleMarkerClick(propertyListing)}
                            icon={{
                                url: mapPin,
                            }}
                        >
                            {activeMarker === propertyListing && (
                                <InfoWindow onCloseClick={closeInfoWindow}>
                                    <div>
                                        <img className="img-fluid"
                                             src={`${process.env.REACT_APP_API_URL}/uploaded_img/${activeMarker.image}`}
                                             width="182"
                                             height="182"/>
                                        <p >{activeMarker.published_title}</p>
                                        <p >₱{activeMarker.price}</p>
                                        <p >{activeMarker.propertytype}</p>
                                        <p >{activeMarker.published_location}</p>
                                        <button className="btn btn-primary"
                                                onClick={() => handleViewProperty(activeMarker.id)}
                                        >View Property
                                        </button>
                                    </div>
                                </InfoWindow>
                            )}
                        </MarkerF>
                    ))}
                </GoogleMap>
            ) : (
                <div>Loading...</div>
            )}
        </>
    )
}

export default MapView;