import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";

function Blog() {
    const [isLoading, setLoading] = useState(true)

    const navigate = useNavigate();
    const [data, setData] = useState([])

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const pageCount = Math.ceil(total / pageSize);

    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blog-client?page=${pageNumber + 1}&page_size=${pageSize}`)
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize])

    function handleViewProperty(id) {
        navigate(`/blog/${id}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (
        <>
            <section data-aos="fade-up">
                <div className="container py-4 py-xl-5">
                    <div className="row mb-5">
                        <div className="col-md-8 col-xl-6 text-center mx-auto">
                            <h2 style={{color: '#805b43'}}>Blogs</h2>
                            <p className="w-lg-50">Description</p>
                        </div>
                    </div>
                    <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                        {data.length === 0 ? (<>
                            <p>No Data.</p>
                        </>) : (data.map((data) => (
                            <div className="col">
                                <div><img className="rounded img-fluid d-block w-100 fit-cover"
                                          style={{height: '200px'}}
                                          src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.featured_image}`}/>
                                    <div className="py-4">
                                        <h4 style={{color: '#805b43'}}>{data.title}</h4>
                                        <p style={{textAlign: 'justify'}}>{data.summary}</p>
                                        <div style={{textAlign: 'center'}}>
                                            <button className="btn btn-primary"
                                                    onClick={() => handleViewProperty(data.id)}>View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <nav style={{display: 'inline-block'}}>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    nextLabel="»"
                                    previousLabel="«"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                    pageClassName="page-item"
                                    previousClassName="page-item"
                                    nextClassName="page-item"
                                    breakClassName="page-item"
                                    disabledClassName="disabled"
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog;