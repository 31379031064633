import backgroundImage from '../../../assets/img/bg.jpg'
import {useNavigate} from "react-router-dom";

function HomeHero() {
    const navigate = useNavigate();

    function handleViewPropertyListing() {
        navigate(`/projects`);
    }

    return (
        <>
            <section data-aos="fade-up" style={{
                background: `url(${backgroundImage}) center / cover no-repeat`,
                paddingTop: '200px',
                paddingBottom: '200px'
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col" style={{
                            textAlign: 'center',
                            background: 'rgba(0,0,0,0.48)',
                            paddingTop: '10px',
                            paddingBottom: '10px'
                        }}>
                            <h1 style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '38px',
                                color: 'rgb(255,255,255)'
                            }}>Demo property development Corporation</h1>
                            <h5 style={{textAlign: 'center', color: 'rgb(255,255,255)'}}><br/>your trusted real estate
                                developer<br/><br/></h5>
                            <button className="btn btn-primary" type="button" style={{
                                color: 'rgb(255,255,255)',
                                borderColor: 'rgb(255,255,255)',
                                background: 'var(--bs-black)'
                            }} onClick={() => handleViewPropertyListing()}>View projects
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeHero;