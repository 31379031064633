import logoToupe from '../../../assets/img/logo_toupe.png'
function HomeAbout() {
 return (
     <>
         <section data-aos="fade-up" style={{paddingTop: '100px', paddingBottom: '100px', background: '#eff1f3'}}>
             <div className="container">
                 <div className="row">
                     <div className="col-lg-7 col-xl-7 col-xxl-7 offset-xxl-0">
                         <h5 className="text-start" style={{color: 'rgb(85,89,92)'}}>About us</h5>
                         <h1 style={{textAlign: 'left', fontSize: '22px', color: '#805b43'}}>Demo property development
                             corporation</h1>
                         <h1 style={{textAlign: 'left', fontSize: '16px'}}>Transforming the Davao City Landscape</h1>
                         <p style={{textAlign: 'justify', color: 'rgb(85, 89, 92)'}}>As a leading real estate developer
                             in Davao City, Demo Property Development Corporation is committed to crafting exceptional
                             living spaces that redefine urban lifestyles. With a focus on innovation, sustainability,
                             and community-centric design, we transform dreams into reality, one project at a time.</p>
                     </div>
                     <div className="col" style={{textAlign: 'center'}}><img className="rounded img-fluid"
                                                                             src={logoToupe}
                                                                             width="451" height="238"/></div>
                 </div>
             </div>
         </section>
     </>
 )
}

export default HomeAbout;