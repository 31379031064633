import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import {ScrollRestoration} from "react-router-dom";

function Body({element}) {
    return (<>
        <ScrollRestoration/>
        <Nav/>
        {element}
        <Footer/>
    </>)
}

export default Body
