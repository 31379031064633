import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
import './components/others/domManipulation';
import {ToastContainer} from "react-toastify";
import Body from "./components/layouts/Body";
import NotFound from "./components/others/NotFound";
import Home from "./components/pages/Home";
import Designs from "./components/pages/Designs";
import Projects from "./components/pages/Projects";
import Blog from "./components/pages/Blog";
import SingleProject from "./components/pages/SinglePages/SingleProject";
import SingleProperty from "./components/pages/SinglePages/SingleProperty";
import SingleDesign from "./components/pages/SinglePages/SingleDesign";
import SingleBlog from "./components/pages/SinglePages/SingleBlog";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path='/' element={<Body element={<Home/>}/>}/>
            <Route path='/designs' element={<Body element={<Designs/>}/>}/>
            <Route path='/projects' element={<Body element={<Projects/>}/>}/>
            <Route path='/blogs/' element={<Body element={<Blog/>}/>}/>
            <Route path='/project/:id' element={<Body element={<SingleProject/>}/>}/>
            <Route path='/project/property/:id' element={<Body element={<SingleProperty/>}/>}/>
            <Route path='/design/:id' element={<Body element={<SingleDesign/>}/>}/>
            <Route path='/blog/:id' element={<Body element={<SingleBlog/>}/>}/>
            <Route path="*" element={<NotFound/>}/>
        </>
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <RouterProvider router={router}/>
    </React.StrictMode>
);
