import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../others/Loading";
import {Typeahead} from "react-bootstrap-typeahead";
import ReactPaginate from "react-paginate";
import MapView from "./SingleProjectSections/MapView";

function SingleProject() {
    const {id} = useParams();
    const [isLoading, setLoading] = useState(true)

    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');
    const [startingPrice, setStartingPrice] = useState('');
    const [endingPrice, setEndingPrice] = useState('');

    const [selectedPriceRange, setSelectedPriceRange] = useState('');

    const [selectedPropertyType, setSelectedPropertyType] = useState([]);
    const [typeId, setTypeId] = useState([]);
    const [propertyType, setPropertyType] = useState([]);

    const pageCount = Math.ceil(total / pageSize);

    const [isMapView, setIsMapView] = useState(false);

    const handleListViewClick = () => {
        setIsMapView(false);
    };

    const handleMapViewClick = () => {
        setIsMapView(true);
    };

    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    const handlePriceRangeChange = (selectedValue) => {
        setSelectedPriceRange(selectedValue);

        // Map selected value to starting and ending prices
        switch (selectedValue) {
            case 'below1M':
                setStartingPrice('0');
                setEndingPrice('999999');
                break;
            case '1Mto2.9M':
                setStartingPrice('1000000');
                setEndingPrice('2900000');
                break;
            case '3Mto4.9M':
                setStartingPrice('3000000');
                setEndingPrice('4900000');
                break;
            case '5Mto6.9M':
                setStartingPrice('5000000');
                setEndingPrice('6900000');
                break;
            case '7Mabove':
                setStartingPrice('7000000');
                setEndingPrice('999999999999999999999999999999999999999999999999999');
                break;
            default:
                // Handle other cases or provide default values
                setStartingPrice('');
                setEndingPrice('');
                break;
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-type/typeahead`, {})
            .then(response => {
                const data = response.data;
                setPropertyType(data.map((data) => ({
                    labelKey: `${data.name}`, id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/project-client/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}&type=${typeId}&startprice=${startingPrice}&endprice=${endingPrice}`)
            .then(response => {
                setData(response.data.data)
                setData2(response.data.data2)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, pageNumber, pageSize, searchTerm, typeId, startingPrice, endingPrice])

    let embeddedUrl = '';
    if (data.yt_video && data.yt_video.trim() !== '') {
        const videoId = data.yt_video.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
            embeddedUrl = `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}?autoplay=1&mute=1`;
        } else {
            embeddedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
        }
    }

    function handleViewProperty(id) {
        navigate(`/project/property/${id}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (
        <>
            {embeddedUrl ? (
                <section data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <iframe allowFullScreen="" frameBorder="0"
                                        src={embeddedUrl}
                                        height="315"
                                        allow="autoplay"
                                        width="560" style={{width: '100%', height: '500px'}}></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            ) : <></>}
            <hr/>
            <section data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-xl-6 text-center mx-auto">
                            <h2 style={{color: '#805b43'}}>{data.name}</h2>
                            <h6 style={{color: '#805b43'}}>{data.propertyownertype.name}</h6>
                            <p className="w-lg-50">{data.location}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className='container'>
                <div className="row">
                    <div className="col text-end" style={{paddingBottom: '10px'}}>
                        <form><label className="form-label" style={{display: 'inline-block'}}>Filter View
                            Type</label>
                            <div className="btn-group" role="group" style={{display: 'block'}}>
                                <button className={`btn btn-primary ${!isMapView ? 'active' : ''}`}
                                        onClick={handleListViewClick}
                                        disabled={!isMapView}>Listing View
                                </button>
                                <button className={`btn btn-primary ${isMapView ? 'active' : ''}`}
                                        onClick={handleMapViewClick}
                                        disabled={isMapView}>Map View
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {isMapView ? <MapView id={id}/> :
                    <div className='row'>
                        <div className="col-xl-12 offset-xl-0" style={{textAlign: 'center'}}>
                            <div className="row">
                                <div className="col-4" style={{textAlign: 'left'}}>
                                    <form style={{display: 'block'}}><label className="form-label"
                                                                            style={{display: 'inline-block'}}>Search
                                        Location</label><input className="form-control" type="text"
                                                               style={{display: 'inline-block'}} value={searchTerm}
                                                               placeholder="Enter Location"
                                                               onChange={e => setSearchTerm(e.target.value)}/></form>
                                </div>
                                <div className="col-4" style={{textAlign: 'left'}}>
                                    <form style={{display: 'block'}}><label className="form-label"
                                                                            style={{display: 'inline-block'}}>Filter
                                        PropertyData Type</label>
                                        <Typeahead
                                            id="id"
                                            labelKey="labelKey"
                                            options={propertyType}
                                            onChange={(selectedOptions) => {
                                                setSelectedPropertyType(selectedOptions)
                                                setTypeId(selectedOptions.map((option) => option.id));
                                            }}
                                            placeholder="Select PropertyData Type"
                                            selected={selectedPropertyType}
                                            required/>
                                    </form>
                                </div>
                                <div className="col-4" style={{textAlign: 'left'}}>
                                    <form style={{display: 'block'}}><label className="form-label"
                                                                            style={{display: 'inline-block'}}>Filter
                                        Price
                                        Range</label> <select
                                        className="form-select"
                                        value={selectedPriceRange}
                                        onChange={(e) => handlePriceRangeChange(e.target.value)}
                                    >
                                        <option value="">Select a Price Range</option>
                                        <option value="below1M">Below 1M</option>
                                        <option value="1Mto2.9M">1M to 2.9M</option>
                                        <option value="3Mto4.9M">3M to 4.9M</option>
                                        <option value="5Mto6.9M">5M to 6.9M</option>
                                        <option value="7Mabove">7M above</option>
                                    </select></form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 offset-xl-0" style={{textAlign: 'center'}}>
                            <hr/>
                        </div>
                        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                            {data2.length === 0 ? (<>
                                <p>No Data.</p>
                            </>) : (data2.map((data) => (
                                <div className="col" key={data.id}>
                                    <div><img className="rounded img-fluid d-block w-100 fit-cover"
                                              style={{height: '200px'}}
                                              src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.single_image}`}/>
                                        <div className="py-4">
                                            <h4 style={{color: '#805b43'}}>{data.published_title}</h4>
                                            <h5 style={{color: '#805b43'}}>₱{data.price}</h5>
                                            <h6 style={{color: '#805b43'}}>{data.propertytype.name}</h6>
                                            <p style={{textAlign: 'justify'}}>{data.published_location}</p>

                                            <div style={{textAlign: 'center'}}>
                                                <button className="btn btn-primary"
                                                        onClick={() => handleViewProperty(data.id)}>View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )))}
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <nav style={{display: 'inline-block'}}>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        nextLabel="»"
                                        previousLabel="«"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                        pageClassName="page-item"
                                        previousClassName="page-item"
                                        nextClassName="page-item"
                                        breakClassName="page-item"
                                        disabledClassName="disabled"
                                    />
                                </nav>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default SingleProject;