import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../others/Loading";
import SingleBlogData from "./SingleBlogSections/SingleBlogData";

function SingleBlog() {
       const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blog-client-single/${id}`, {})
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
                navigate(`/blogs`);
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, navigate])

       if (isLoading) {
        return (
            <Loading/>
        );
    }
 return (
    <>
        <SingleBlogData data={data}/>
    </>
 )
 }

export default SingleBlog;