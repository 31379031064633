import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import SingleDesignData from "./SingleDesignSections/SingleDesignData";
import Loading from "../../others/Loading";

function SingleDesign() {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/design-client-single/${id}`, {})
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
                navigate(`/designs`);
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, navigate])

    if (isLoading) {
        return (
            <Loading/>
        );
    }

    return (
        <>
            <SingleDesignData data={data}/>
        </>
    )
}

export default SingleDesign;