import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import SinglePropertyInquire from "./SinglePropertySections/SinglePropertyInquire";
import HomeOffice from "../HomeSections/HomeOffice";
import SinglePropertyData from "./SinglePropertySections/SinglePropertyData";

function SingleProperty() {
    const {id} = useParams();
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [realty, setRealty] = useState([])
    const inquireRef = useRef(null);
    const scrollToInquire = () => {
        inquireRef.current.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-client/${id}`, {})
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
                navigate(`/projects`);
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, navigate])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/realty`)
            .then(response => {
                setRealty(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        );
    }


    return (
        <>
            <SinglePropertyData data={data} scrollToInquire={scrollToInquire}/>
            <div ref={inquireRef}>
                <SinglePropertyInquire realty={realty} data={data}/>
            </div>
            <hr/>
            <HomeOffice realty={realty}/>
        </>
    )
}

export default SingleProperty;